import styled from 'styled-components'

export const AboutContainer = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    text-align:center;
    justify-content:center;
`
const AboutItems = styled.div`
    display: flex;
    flex-direction:column;
    background-color:${props => props.color || "transparent"};
    padding:80px;
    h1 {
        font-size:24px;
        font-weight:600;
        margin: 0px 0px 20px 0px;
    }
    p {
        margin: 0px 150px;
        line-height:2;
        @media (max-width: 900px){
            margin: 0px 10px;
        }
    }
    @media (max-width: 900px) {
        padding:50px;
    }
`
export function AboutItem(props){
    return(
        <AboutItems color={props.color}>
            <h1>{props.title}</h1>
            <p>{props.children}</p>
        </AboutItems>
    )
}