import React,{useRef, useState} from 'react';
import styled from 'styled-components';
import {firestore} from '../../firebase'
import firebase from 'firebase'

const PopUp = styled.div`
    display:${props => props.pop?"flex":"none"};
    justify-content:center;
    align-items:center;
    text-align:center;
    position:fixed;
    bottom:30px;
    left:50%;
    transform:translateX(-50%);
    height:50px;
    padding: 5px 20px;
    background-color:#222222;
    color:white;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
    @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
    }

    @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
    }

    @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
    }

    @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
    }
`

const ContactForm = styled.form`
    display: flex;
    flex-direction:column;
    align-items:left;
    text-align: left;
    width:100%;
    & > input {
        margin: 5px 0px 10px 0px;
        padding: 5px 10px;
        letter-spacing:1px;
    }
    input[type=submit]{
        margin-top:10px;
        transition: all ease 0.50s;
        text-decoration: none;
        padding: 5px 20px;
        font-size: 14px;
        color: white;
        border: 1px solid black;
        background: linear-gradient(to right, transparent 50%, black 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        &:hover{
            background: linear-gradient(to right, transparent 50%, black 50%);
            background-size: 200% 100%;
            background-position: left bottom;
            color: black;
            cursor: pointer;
        }
        @media (max-width: 900px) {
            background: black;
        }
    }
`

function Contact() {
    const [pop,setPop] = useState(false);
    const submit = useRef(null);

    const handleFormSubmit = (e)=>{
        e.preventDefault();
        const {target} = e;
        const data = {
            phone: target['0'].value,
            email: target['1'].value,
            query: target['2'].value,
            status: false,
            date: new Date()
        }
        if(!/^([0-9]{10,})$/.test(data.phone)){
            setPop(true);
            setTimeout(function(){ setPop(false) }, 3000);
            target.['0'].focus();
        }
        else if(!/['@']/.test(data.email)){
            setPop(true);
            setTimeout(function(){ setPop(false) }, 3000);
            target.['1'].focus();
        }
        else{
            submit.current.value = "Thanks for Contacting Us";
            submit.current.disabled = true;
            setTimeout(()=>{
                submit.current.value = "Click to Submit";
                submit.current.disabled = false;
            },10000);
            firestore.collection("data").doc("c").update({
                all: firebase.firestore.FieldValue.arrayUnion(data)
            }).then(()=>{
                console.log("submitted");
            }).catch((error)=>console.log(error.message));
        }
    }
    return (
        <>
            <ContactForm autoComplete="off" id="contact-us" onSubmit={handleFormSubmit}>
                <label htmlFor="phone">Phone Number</label>
                <input type="text" id="phone" name="phone" placeholder="123456789"/>
                <label htmlFor="email">Email Id</label>
                <input type="text" id="email" name="email" placeholder="abc@gmail.com"/>
                <label htmlFor="query">What you would like to ask?</label>
                <input type="textarea" id="query" name="query" placeholder="Enter what you will like to ask"/>
                <input type="submit" value="Click to Submit" ref={submit}/>
            </ContactForm>
            <PopUp pop={pop}>ENTER CORRECT DETAILS</PopUp>
        </>
    )
}

export default Contact
