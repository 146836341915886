import React,{useEffect} from 'react';
import {AboutContainer,AboutItem} from './AboutElements';

function About() {
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return (
        <>
            <AboutContainer>
                <AboutItem title="WHO WE ARE" color="#eee">
                    Lajshree Enterprises has its intense focus on providing constant value
                    to its customers through its stringent processes and practices.
                    We deal in highest quality products and service standards.
                    We belive in commitement and making full efforts to understand the buyers need.
                    We provide Pet Bottles, PP Caps, Measuring Caps, Alu Alu Base Foils, Rigid PVC, Lids, Tablet Applicators 
                    and many other pharma stationaries. 
                </AboutItem>
            </AboutContainer>
        </>
    )
}

export default About
