import React, { useEffect, useRef, useState } from 'react'
import {storage,firestore} from '../../firebase'
import firebase from 'firebase/app'
import styled from 'styled-components'
import imageCompression from 'browser-image-compression';

const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    min-height: 100vh;
    padding:100px 50px;
    form {
        display: flex;
        flex-direction:column;
        padding: 10px 20px 50px 20px;
        background-color: #f3f3f3;
        border: solid black 1px;
        max-width:50%;
        img {
            align-self:center;
            margin:0px 0px 20px 0px;
            max-height: 200px;
        }
        input {
            margin-bottom:20px;
            padding:5px 10px;
        }
        span {
            margin-bottom:5px;
        }
    }
`

function Admin({data}) {
    const [url,setURL] = useState('');
    const [file, setFile] = useState(null);
    const [progress,setProgress] = useState(null);
    const [error,setError] = useState(null);
    const [name,setName] = useState(null);
    const [type,setType] = useState(null);
    const [size,setSize] = useState(null);
    const form = useRef(null);

    const handleSubmit = (e)=>{
        e.preventDefault();
        if(!file){
            console.error("No file selected");
            return
        }
        const uploadTask = storage.ref(`/images/${name}`).put(file);
        uploadTask.on("state_changed", (snapshot) => {
            let percentage = (snapshot.bytesTransferred/ snapshot.totalBytes) * 100;
            setProgress(percentage + "% done and " + snapshot.state);
        }, (error) => {
            console.log(error);
            setError(error.message);
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadUrl)=>{
                setURL(downloadUrl);
                form.current.reset();
                let data = {
                    'name': name,
                    'type': type.split(",").map(e=>e.trim()).filter(e => e.length>0),
                    'size': size.split(",").map(e=>e.trim()).filter(e => e.length>0),
                    'url':downloadUrl
                }
                firestore.collection("data").doc("i").update({
                    list: firebase.firestore.FieldValue.arrayUnion(data),
                    u: firebase.firestore.FieldValue.serverTimestamp()
                }).then(()=>{
                    console.log("db updated");
                    setProgress("completed");
                    setError(null);
                    setFile(null);
                    setName(null);
                    // setType(null);
                    setSize(null);
                }).catch((error)=>console.log(error));
            })
        });
    }
    const handleChange = (e)=> {
        const options = {
            maxSizeMB: 0.02,
            maxWidthOrHeight: 640,
            useWebWorker: true
          }
        imageCompression(e.target.files[0],options).then(compressedFile => {
            console.log("Original : " + e.target.files[0].size/1024 + " Compressed : " + compressedFile.size/1024);
            setURL(URL.createObjectURL(compressedFile));
            setFile(compressedFile);
            setName(compressedFile.name);
            setSize(compressedFile.name.split('-').slice(0,2).join("-"));
        }).catch(error=> console.error(error.message));
        
    }

    useEffect(()=>{
        console.log(data);
    },[data])

    return (
       <Container>
           <form onSubmit={handleSubmit} ref={form}>
            <span>{error?error:progress}</span>
            <img src={url} alt="" />
            <input type="file" onChange={handleChange} />
            {file?(<span>File Name</span>):''}
            {file?(<input type="text" value={name} onChange={(e)=>setName(e.target.value)}/>):''}
            {file?(<span>Type (Like pet-bottle)</span>):''}
            {file?(<input type="text" value={type} onChange={(e)=>setType(e.target.value)}/>):''}
            {file?(<span>Size</span>):''}
            {file?(<input type="text" value={size} onChange={(e)=>setSize(e.target.value)}/>):''}
            <button >Upload</button>
           </form>
       </Container> 
    )
}

export default Admin
