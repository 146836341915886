import React from 'react';
import {Banner,ProductPreview,KeyPoints, PreviewAbout} from './HomeElements';

function Home({data}) {

    return (
        <>
        <Banner></Banner>
        <ProductPreview data = {data}></ProductPreview>
        <KeyPoints></KeyPoints>
        <PreviewAbout></PreviewAbout>
        </>         
    )
}

export default Home
