import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {IconContext} from "react-icons";
import {RiArrowDropDownLine} from 'react-icons/ri'

export const ProductMainContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:stretch;
    width:100%;
    /* padding: 0px 30px; */
    & > h1{
        margin-top:100px;
        font-size:24px;
        font-weight:700;
        align-self:center;
    }
    & > p{
        margin: 0px 15px;
        margin-top:10px;
        align-self:center;
        text-align:center;
    }
`

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:flex-start;
    text-align:center;
    width: 20%;
    /* border:1px solid black; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    align-self:stretch;
    margin:30px;
    max-width:400px;
    overflow:hidden;
    @media (max-width: 900px) {
        width: 38%;
        margin: 15px 10px;
    }
    @media (max-width: 600px) {
        width: 46%;
        min-width:150px;
        margin: 30px 5px;
    }
`
const StyledImgCont = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:${props=>(props.win*0.2 + "px")};
    height:${props=>(props.win*0.2 + "px")};
    overflow:hidden;
    @media (max-width: 900px) {
        width:${props=>(props.win*0.38 + "px")};
        height:${props=>(props.win*0.38 + "px")};
    }
    @media (max-width: 600px) {
        width:${props=>(props.win*0.46 + "px")};
        height:${props=>(props.win*0.46 + "px")};
    }
`
const StyledImage = styled.img`
    width:100%;
`
const ProductText = styled.div`
    font-size:15px;
    font-weight:500;
    padding: 10px 30px;
    border-radius:10px;
    @media (max-width: 900px){
        font-size:14px;
        padding:5px 5px;
    }
    /* background-color: #a3a3a3; */
`
export function ProductItem({item}) {
    const [width,setWidth] = useState(window.innerWidth)
    useEffect(()=>{
        const handleResize = () => {
            setWidth(window.innerWidth)
        };
        window.addEventListener('resize', handleResize);
        return ()=> window.removeEventListener('resize', handleResize);
    },[])
    return (
        <StyledContainer>
            <StyledImgCont win={width}>
                <StyledImage src={item.url} alt={item.name}></StyledImage>
            </StyledImgCont>
            <ProductText>{item.name.replace('.jpg','').replace(/-/g," ")}</ProductText>
        </StyledContainer>
    )
}

export const ProductContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction:row;
    justify-content:space-around;
    margin:10px 0px;
`

const FilterContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    align-self:stretch;
    justify-content:stretch;
    text-align:center;
    padding: 10px 50px;
    margin-top:20px;
    @media (max-width : 900px){
        padding:10px;
    }
`
const FilterItem = styled.div`
    width:100%;
    /* box-sizing:border-box; */
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    text-align:center;
    background-color:#eee;
    margin:10px;
    & > h3{
        flex-shrink:0;
        box-sizing:border-box;
        width:10%;
        display:flex;
        flex-direction:row;
        justify-content:center;
        padding: 10px 0px;
        background-color:#ddd;
        span {
            align-self:center;
            font-size:18px;
        }
        .drop {
            position:absolute;
            align-self:center;
            display:none;
            transform:translateX(50px);
            @media (max-width: 900px){
                display:block;
            }
        }
        @media (max-width: 900px) {
            width:100%;
        }
    }
    & > div {
        display:flex;
        flex-direction:row;
        flex-wrap:wrap;
        @media (max-width: 900px) {
            /* display:none; */
            width:90vw;
            flex-wrap: nowrap;
            overflow-x:scroll;
            align-items:center;
            justify-content:flex-start;
            padding-bottom:10px;
        }
    }
    & button {
        margin: 10px;
        border: 1px solid black;
        background-color:none;
        text-decoration:none;
        padding: 1px 10px;
        white-space:nowrap;
        
        &:hover{
            cursor:pointer;
            background-color: #388E8E;
            color: white;
        }
    }
    @media (max-width: 900px) {
        flex-direction:column;
        align-items:center;
    }
`

export function Filter({setItems,data}){
    const [type,setType] = useState([]);
    const [size,setSize] = useState([]);
    const [showType,setShowType] = useState(window.innerWidth>=900);
    const [showSize,setShowSize] = useState(window.innerWidth>=900);

    const Type = data.map(item => item.type);
    const Size = data.map(item => item.size);
    let types = [];
    let sizes = [];
    for (let i = 0; i < Type.length; i++) {
        types = [...new Set(types.concat(Type[i]))]
    }
    for (let i = 0; i < Size.length; i++) {
        sizes = [...new Set(sizes.concat(Size[i]))]
    }
    sizes = sizes.filter(i => i.length>0).sort((a,b)=> parseInt(a) - parseInt(b));
    types = types.sort((a,b)=>a.localeCompare(b));



    const handleFilterClick = (value,filter) => {
        switch(filter){
            case 'type':
                if(type.includes(value)){
                    setType(type.filter(item => item!== value));
                    if(value==='pet-bottle'){setSize([])};
                } else {
                    setType([...type,value])
                }
                break;
            case 'size':
                if(size.includes(value)){
                    setSize(size.filter(item => item!== value));
                } else {
                    setSize([...size,value])
                }
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        setItems(data.filter(item => {
            let test = 0;
            if(type.length + size.length < 1){
                return true;
            }
            if(type.length<1){
                test = test + 1;
            }
            if(size.length<1 || item.size.length < 1){
                test = test + 1;
            }
            for(let i = 0;i< item.type.length;i++){
                if(type.includes(item.type[i])){
                    test = test + 1;
                    break;
                }
            }
            for(let i = 0;i< item.size.length;i++){
                if(size.includes(item.size[i])){
                    test = test + 1;
                    break;
                }
            }
            if(test > 1){
                return true;
            }
            return false;
        }).sort((a,b) => a.name.localeCompare(b.name)))
    }, [type,size])
    return(
        <FilterContainer>
            <FilterItem>
                <h3 onClick={()=>setShowType(window.innerWidth>=900 || !showType)}>
                    <span>TYPE</span>
                <IconContext.Provider value={{size:"5%", color:"black", className:"drop"}}>
                    <RiArrowDropDownLine/>
                </IconContext.Provider>
                </h3>
                <div style={{display:showType?'flex':'none'}}>
                    { types.map((value,index) => (<button key={index} onClick={()=>handleFilterClick(value,"type")} style={{backgroundColor: type.includes(value)?'#388E8E':'transparent', color:type.includes(value)?'white':'black'}}>{value}</button>)) }
                </div>
            </FilterItem>
            <FilterItem style={{display:type.includes('pet-bottle')?'flex':'none'}}>
                <h3 onClick={()=>setShowSize(window.innerWidth>=900 || !showSize)}>
                <span>SIZE</span>
                <IconContext.Provider value={{size:"5%", color:"black", className:"drop"}}>
                    <RiArrowDropDownLine/>
                </IconContext.Provider>
                </h3>
                <div style={{display:showSize?'flex':'none'}}>
                    { sizes.map((value,index) => (<button key={index} onClick={()=>handleFilterClick(value,"size")} style={{backgroundColor: size.includes(value)?'#388E8E':'transparent', color:size.includes(value)?'white':'black'}}>{value}</button>)) }
                </div>
            </FilterItem>
        </FilterContainer>
    )
}