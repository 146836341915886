import React, {useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import {FlexContainer,FlexItem, StyledLink, StyledHeaderLink,StyledButtonAnchor,BarsIcon,MobNavLink,MobNavLinks} from './NavbarElements';
import {IconContext} from "react-icons";
import {FaBars,FaPhoneAlt} from 'react-icons/fa';
import {ImCross} from 'react-icons/im';
import {SiGmail} from 'react-icons/si'

const orig = {
    bgcolor: "white",
    textColor: "black"
};
const chng = {
    bgcolor: "transparent",
    textColor: "white"
};

function Navbar() {
    const [color,setColor] = useState(chng);
    const [bars,setBars] = useState(true);

    const location = useLocation();
    useEffect(()=>{
        const handleNavScroll = () => {
            if (window.scrollY > 20 || location.pathname !== "/") {
            setColor(orig);
            } else {
            setColor(chng);
            }
        };
        window.addEventListener("scroll", handleNavScroll);
        return () => window.removeEventListener("scroll", handleNavScroll);
    },[location.pathname]);

    
    useEffect(() => {
        if(location.pathname ==="/about" || location.pathname === "/products"){
            setColor(orig);
        }
        else{
            setColor(chng);
        }
        window.scrollTo(0,0);
    }, [location]);

    const handleBarCLick =()=> setBars(!bars);
    const handleContactUs = ()=>{
        const element = document.getElementById('contact-us');
        if (element) element.scrollIntoView();
    }
    return (
        <>
            <FlexContainer bgcolor={color.bgcolor} hovercolor="#ffffff">
                <FlexItem align="left">
                    <StyledLink to="/about" color={color.textColor} hovercolor="#a3733b">ABOUT</StyledLink>
                    <StyledLink to="/products" color={color.textColor} hovercolor="#a3733b">PRODUCTS</StyledLink>
                </FlexItem>
                <FlexItem>
                    <StyledHeaderLink to="/" color={color.textColor === 'black'?'rgb(184, 0, 55)':color.textColor} hovercolor="#FF221F" >LAJSHREE</StyledHeaderLink>
                </FlexItem>
                <FlexItem align="right">
                    <StyledButtonAnchor onClick={handleContactUs} color={color.textColor} hovercolor="#a3733b">CONTACT US</StyledButtonAnchor>
                </FlexItem>
                <BarsIcon display={bars} onClick={handleBarCLick}>
                    <IconContext.Provider value={{size:"25px", color:`${color.textColor}`}}>
                        <FaBars/>
                    </IconContext.Provider>
                </BarsIcon>
                <BarsIcon display={!bars} onClick={handleBarCLick}>
                    <IconContext.Provider value={{size:"25px", color:`${color.textColor}`}}>
                        <ImCross/>
                    </IconContext.Provider>
                </BarsIcon>
            </FlexContainer>
            <MobNavLinks display={!bars}>
                <MobNavLink to="/about" onClick={handleBarCLick}>ABOUT</MobNavLink>
                <MobNavLink to="/" onClick={handleBarCLick}>HOME</MobNavLink>
                <MobNavLink to="/products" onClick={handleBarCLick}>PRODUCTS</MobNavLink>
                <a onClick={()=> {handleBarCLick();handleContactUs();}} style={{display:"block",
                flex:"1 0 100%",
                textDecoration:"none",
                fontSize:"18px",
                color:"white"}}>CONTACT US</a>
                <a href="mailto:lajshree@gmail.com" onClick={handleBarCLick} style={{flex:"1 0 100%",marginTop:"30px",textDecoration:"none",fontSize:"18px",
                    color:"#EA4335",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                    <IconContext.Provider value={{size:"25px", color:"#EA4335", style:{marginRight:"10px"}}}>
                        <SiGmail/>
                    </IconContext.Provider>
                    lajshree@gmail.com
                </a>
                <a href="tel:+91783-204-6511" onClick={handleBarCLick} style={{flex:"1 0 100%",marginTop:"30px",textDecoration:"none",fontSize:"18px",
                    color:"#06d6a0",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
                    <IconContext.Provider value={{size:"25px", color:"#06d6a0", style:{marginRight:"10px"}}}>
                        <FaPhoneAlt/>
                    </IconContext.Provider>
                    +91-783-204-6511
                </a>
            </MobNavLinks>
        </>
    )
}

export default Navbar
