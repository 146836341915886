import React,{useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {StyledButtonLink} from '../Navbar/NavbarElements';
import {ProductItem} from '../Product/ProductElements';
import {Link} from 'react-router-dom';
import {AiFillCaretLeft,AiFillCaretRight} from 'react-icons/ai'
import {IconContext} from "react-icons";
import {SiGmail} from 'react-icons/si'
import {FaPhoneAlt,FaHandshake} from 'react-icons/fa';
import {GiTwoCoins} from 'react-icons/gi'
import {GiAchievement} from 'react-icons/gi';
import {IoIosTimer} from 'react-icons/io';
import ContactForm from './Contact';


const Scene = styled.div`
    width: 200px;
    height: 200px;
    perspective: 400px;
`
const Cube = styled.div`
    width: 200px;
    height: 200px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-100px) ${props => props.show};
    transition: transform 1s;
`
const CubeFace = styled.div`
    width: 200px;
    height: 200px;
    /* border: 2px solid #aaaaaa; */
    line-height: 200px;
    font-size: 40px;
    font-weight: bold;
    color: white;
    text-align: center;
    position: absolute;
    background-color:${props => "rgba(" + props.color + ",1)" || "transparent"};
    /* background-color:hsla(  0, 0%, 80%, 0.95); */
    transform: ${props => props.front?"rotateY(  0deg)":props.right?"rotateY(  90deg)":props.back?"rotateY(180deg)":props.left?"rotateY(-90deg)":props.top?"rotateX( 90deg)":"rotateX(-90deg)"} translateZ(100px); 
`
const List = ["translateZ(-100px) rotateY(  0deg)","translateZ(-100px) rotateY( -90deg) ","translateZ(-100px) rotateY(-180deg)","translateZ(-100px) rotateY(90deg)","translateZ(-100px) rotateX( -90deg)","translateZ(-100px) rotateX(90deg)"];

export function LogoAnimation(){
    const [show,setShow] = useState("rotateY(  0deg)");
    useEffect(() => {
        const interval = setInterval(() => {
            setShow((prev)=>{
                let i = Math.floor(Math.random() * List.length);
                if(prev == List[i]){
                    return List[(i + 1)%List.length];
                }
                return List[i];
            })
        }, 1500);
        return () => clearInterval(interval);
      }, []);

    return(
        <Scene>
            <Cube show={show}>                
                <CubeFace front color="240, 206, 160"><img src="./assests/images/logo.svg" alt="Logo" /></CubeFace>
                <CubeFace back color="240, 206, 160"><img src="./assests/images/logo.svg" alt="Logo" /></CubeFace>
                <CubeFace right color="240, 206, 160"><img src="./assests/images/logo.svg" alt="Logo" /></CubeFace>
                <CubeFace left color="247, 240, 240"><img src="./assests/images/logo.svg" alt="Logo" /></CubeFace>
                <CubeFace top color="247, 240, 240"><img src="./assests/images/logo.svg" alt="Logo" /></CubeFace>
                <CubeFace bottom color="247, 240, 240"><img src="./assests/images/logo.svg" alt="Logo" /></CubeFace>
            </Cube>
        </Scene>
    )
}

const BannerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    text-align:center;
    height:100vh;
    min-height: 600px;
    width:100%;
    font-family: Montserrat, sans-serif;
    line-height: 1.65;
    font-size:16px;
    background: #333333 url(./assests/images/homebg.jpg) no-repeat center;
    background-size:cover;
    background-blend-mode: multiply;
`
const BannerHeading = styled.div`
    margin-top:30px;
    font-size:36px;
    font-weight:700;
    color:#fff;
`
export const BannerText = styled.div`
    font-size:18px;
    font-weight:500;
    color:#FFA400;
`
const StyledButton = styled(StyledButtonLink)`
    border-radius: 0px;
    border-color: white;
    color: black;
    background: linear-gradient(to right, transparent 50%, white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    &:hover{
        background: linear-gradient(to right, transparent 50%, white 50%);
        background-size: 200% 100%;
        background-position: left bottom;
    }
    @media (max-width : 900px){
        background:#3B4210;
        border:none;
        color:white;
    }
`
export function Banner() {
    return (
        <BannerContainer>
            <LogoAnimation></LogoAnimation>
            <BannerHeading>LAJSHREE ENTERPRISES</BannerHeading>
            <BannerText>DEALS IN PHARMA PACKAGING</BannerText>
            <StyledButton to="/products">CHECK OUR PRODUCTS</StyledButton>
        </BannerContainer>
    )
}

const PreviewContainer = styled.div`
    display: flex;
    position:relative;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items:center;
    text-align:center;
    padding:50px 10px;
    .scrollcont{
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:center;
        @media (max-width: 900px){
            display:none;
        }
    }
    .scrollbtn{
        margin: 0px 10px;
        &:hover{
            cursor:pointer;
        }
    }
`
const ProductContainer = styled.div`
    display: flex;
    position: relative;
    flex-shrink:0;
    box-sizing:border-box;
    width:100%;
    flex-direction: row;
    justify-content:flex-start;
    align-items:flex-start;
    flex-wrap:nowrap;
    padding: 30px 10px;
    & > div {
        margin:10px 5px 0px 5px;   
        flex: 1 0 20%;
        @media (max-width: 900px) {
            flex: 1 0 38%;
        }
        @media (max-width: 600px) {
            flex: 1 0 46%;
        }        
    };
    & > div:first-child{
        margin-left:-10px;
    }
    & > div:last-child{
        margin-right:20px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    overflow-x:scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
`
const PreviewHeading = styled.h3`
    font-size: 24px;
    margin-bottom: 10px;
    font-weight:600;
`
const PreviewButton = styled(Link)`
    transition: all ease 0.1s;
    text-decoration: none;
    border:1px solid transparent;
    border-bottom:${props => props.active?"1px solid black": "1px solid transparent"};
    color: black;
    background-color: transparent;
    font-size: 16px;
    padding:10px 5px;
    font-weight: 500;
    margin:10px;
    &:hover{
        border-bottom: 1px solid black;
    }
`
const ProductExploreButton = styled(StyledButton)`
    color: white;
    border-color: black;
    background: linear-gradient(to right, transparent 50%, black 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    &:hover{
        background: linear-gradient(to right, transparent 50%, black 50%);
        background-size: 200% 100%;
        background-position: left bottom;
        color: black;
    }
    @media (max-width: 900px) {
        background: black;
    } 
`

export function ProductPreview({data}){
    const [bottle,setBottle] = useState(true);
    const [show,setShow] = useState([]);
    
    useEffect(()=>{
        if(bottle){
            setShow(data.filter(el => el.type.includes('pet-bottle')).slice(0,6).sort((a,b) => a.name.localeCompare(b.name)));
        } else {
            setShow(data.filter(el => !el.type.includes('pet-bottle')).slice(0,6));
        }   
    },[bottle,data]);

    const scrollRef = useRef(null);
    const handleClick = (type)=>{
        if(type==="left"){
            scrollRef.current.scrollBy(-window.innerWidth*0.8,0)
        }
        else if(type ==="right"){
            scrollRef.current.scrollBy(window.innerWidth*0.8,0)
        }
        else{
            console.log(scrollRef);
        }
    }
    return(
        <PreviewContainer>
            <PreviewHeading>OUR PRODUCTS</PreviewHeading>
            <p>We deal in wide variety of products.<br/>Contact us for more information if you cant find your product on website.<br/><br/></p>
            <div>
                <PreviewButton onClick={()=>setBottle(true)} active={bottle}>Bottles</PreviewButton>
                <PreviewButton onClick={()=>setBottle(false)} active={!bottle}>Other Items</PreviewButton>
            </div>
            
            
            <ProductContainer ref={scrollRef}>
                {show.map((item)=>(<ProductItem item={item} key={item.name} />))}
            </ProductContainer>
            
            
            <div className="scrollcont">
                <IconContext.Provider value={{size:"25px", color:"black"}}>
                    <AiFillCaretLeft className="scrollbtn" onClick={()=>handleClick("left")}/>
                </IconContext.Provider>
                CLICK TO SCROLL
                <IconContext.Provider value={{size:"25px", color:"black"}}>
                    <AiFillCaretRight className="scrollbtn" onClick={()=>handleClick("right")}/>
                </IconContext.Provider>
            </div>
            <ProductExploreButton to="/products">EXPLORE ALL PRODUCTS</ProductExploreButton>
        </PreviewContainer>
    )
}

const KeysContainer = styled.div`
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    text-align: center;
`
const KeyContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    text-align:center;
    color: white;
    width:25%;
    & > h3{
        font-size:24px;
        font-weight:600;
        margin:0px 50px 10px 50px;
        @media (max-width: 420px){
            font-size:20px;
        }
    }
    & > p{
        font-size:14px;
        margin:0px 50px 50px 50px;
    }
    overflow: hidden;
    word-wrap:break-word;
    background-color: ${props => props.color || "#111111"};
    @media (max-width: 900px) {
        width:50%;
        &:nth-child(3){
            order:4;
        }
        & > h3{
            margin:0px 30px 10px 30px;
        }
        & > p{
            font-size:14px;
            margin:0px 30px 10px 30px;
        }
    }
    @media (max-width: 400px) {
        width:100%;
        & > h3{
            margin:0px 30px 10px 30px;
        }
        & > p{
            font-size:14px;
            margin:0px 30px 10px 30px;
        }
        &:nth-child(4){
            order:4;
        }
    }
`


export function KeyPoints() {

    return (
       <KeysContainer>
           <KeyContainer>
                <IconContext.Provider value={{size:"50%", color:"white", style:{margin:"50px 0px 30px 0px"}}}>
                    <FaHandshake/>
                </IconContext.Provider>
                <h3>TRUST</h3>
           </KeyContainer>
           <KeyContainer color="rgba(17, 17, 17, 0.9)">
                <IconContext.Provider value={{size:"50%", color:"white", style:{margin:"50px 0px 30px 0px"}}}>
                    <GiAchievement/>
                </IconContext.Provider>
                <h3>QUALITY</h3>
           </KeyContainer>
           <KeyContainer>
                <IconContext.Provider value={{size:"50%", color:"white", style:{margin:"50px 0px 30px 0px"}}}>
                    <IoIosTimer/>
                </IconContext.Provider>
                <h3>TIMELY</h3>
           </KeyContainer>
           <KeyContainer color="rgba(17, 17, 17, 0.9)">
                <IconContext.Provider value={{size:"50%", color:"white", style:{margin:"50px 0px 30px 0px"}}}>
                    <GiTwoCoins/>
                </IconContext.Provider>
                <h3>BEST DEALS</h3>
           </KeyContainer>
       </KeysContainer>
    )
}

const AboutContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    text-align: center;
    padding: 50px 5vw;
`
const AboutHeading = styled.h2`
    font-size:24px;
    color:black;
    font-weight:600;
`
const AboutText = styled.p`
    font-size:14px;
    color: black;
    letter-spacing:1.6px;
    margin:40px 0px;
`

export function PreviewAbout() {
    return (
        <AboutContainer>
            <AboutHeading>WHAT WE DO</AboutHeading>
            <AboutText>
                <p>We provide best Pharma Products at very reasonable rate.
                <br/>We deal in wide variety of products like Pet Bottles, Measuring Caps, PP Caps, Base Foils, Droppers and many more.
                <br/>In past few years, we have built very strong connections in market and are constantly working to build more connections.
                <br/>Contact us if you want some product at best market value.
                </p>
            </AboutText>
            <ProductExploreButton to="/about">KNOW MORE ABOUT US</ProductExploreButton>
        </AboutContainer>
    )
}


const FooterContainer = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content:space-between;
    text-align:center;
    padding: 30px 30px;
    background-color:#efefef;
    @media (max-width: 900px) {
        flex-direction:column;
        align-items: stretch;
    }
`
const FooterItem = styled.div`
    flex: ${props => props.flex || "1"};
    flex-shrink:0;
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content:center;
    text-align:left;
    padding: 0px 50px;
    & > h1 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    & > div{
        margin-top:30px;
    }
    @media (max-width: 900px) {
        margin-bottom:50px;
        padding: 0px 0px;
        text-align:center;
        align-items:center;
        &:first-child{
            order:3;
            margin-bottom:10px;
        }
        &:nth-child(2){
            display:none;
        }
        & > div{
            margin-top:10px;
        }
    }
`
const FooterLink = styled(Link)`
    text-decoration: none;
    color: black;
`


export function Footer() {
    return (
        <>
        <FooterContainer>
            <FooterItem flex="2">
                <h1 style={{color:"#08575E",fontSize: '20px', fontWeight:'900'}}>LAJSHREE ENTERPRISES</h1>
                <p>Baddi-Chakka Road, Near Cipla Colony, Village - Chakka, Tehsil - Baddi, District - Solan, Himachal Pradesh, 173205</p>
                <p>Proprietor: Kumar Lava</p>
                <p>Gmail: lajshree@gmail.com</p>
                <p>Phone: 7832046511</p>
                <div>
                    <a href="mailto:lajshree@gmail.com" style={{textDecoration:"none",fontSize:"18px",
                    color:"black"}}>
                    <IconContext.Provider value={{size:"25px", color:"red", style:{margin:"20px 30px 20px 5px"}}}>
                        <SiGmail/>
                    </IconContext.Provider></a>
                    <a href="tel:+91783-204-6511" style={{textDecoration:"none",fontSize:"18px",
                    color:"black"}}>
                    <IconContext.Provider value={{size:"25px", color:"#000080", style:{margin:"20px 0px"}}}>
                        <FaPhoneAlt/>
                    </IconContext.Provider></a>
                </div>
                <div>
                 COPYRIGHT &copy; 2021 LAJSHREE ENTERPRISES.<br/> ALL RIGHTS RESERVED
                </div>
            </FooterItem>
            <FooterItem>
                <h1>LINKS</h1>
                <FooterLink to="/about" >About Us</FooterLink>
                <FooterLink to="/" >Home</FooterLink>
                <FooterLink to="/products" >Products</FooterLink>
            </FooterItem>
            <FooterItem flex="2">
                <h1>CONTACT US</h1>
                <ContactForm/>
            </FooterItem>
        </FooterContainer>
        </>
    )
}