import React,{useEffect, useState} from 'react';
import {ProductItem,ProductContainer,Filter,ProductMainContainer} from './ProductElements';



function Product({data}) {
    const [items,setItems] = useState([...data].sort((a,b) => a.name.localeCompare(b.name)));
    useEffect(()=>{
        setItems([...data].sort((a,b) => a.name.localeCompare(b.name)));
    },[data])

    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);

    return (
        <>
        <ProductMainContainer>
            <h1>OUR PRODUCTS</h1>
            <p>We deal in wide variety of products. Contact us for more information if you cant find your product on website.</p>
            <Filter setItems={setItems} data={data}></Filter>
            <ProductContainer>
                {items.map(item => (<ProductItem item={item} key={item.name}/>))}
            </ProductContainer>
        </ProductMainContainer>
        </>
    )
}

export default Product
