import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Switch, Route} from "react-router-dom";
import './App.css';
import Nav from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Product from './Components/Product/Product';
import About from './Components/About/About';
import Admin from './Components/Admin/Admin';
import {Footer} from './Components/Home/HomeElements';
import { firestore } from './firebase';


function App() {
  const [data,setData] = useState([]);
  useEffect(()=>{
    firestore.collection("data").doc("i").get().then((doc)=>{
      if(doc.exists){
        setData(doc.data().list.sort(()=> Math.random() - 0.5));
      }
    }).catch(error => console.log(error.message));
  },[]);

  return (
    <>
    <Router hashType="noslash">
    <Nav/>
      <Switch>
        <Route path="/admin"  exact render={(props)=>(<Admin {...props} data={data}/>)}/>
        <Route path="/about" exact render={(props)=>(<About {...props} />)}/>
        <Route path="/products" exact render={(props)=>(<Product {...props} data={data}/>)}/>
        <Route path="/" render={(props)=>(<Home {...props} data={data}/>)}/>
      </Switch>
      <Footer/>
    </Router>
    </>
  );
}

export default App;