import styled from 'styled-components'
import {Link} from "react-router-dom";

export const FlexContainer = styled.div.attrs(props=>({
    bgcolor: props.bgcolor || "transparent",
}))`
    display: flex;
    position: fixed;
    top:0px;
    width: 100vw;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    font-family: Montserrat, sans-serif;
    line-height: 1.65;
    padding: 10px 30px;
    background-color: ${props => props.bgcolor};
    transition: all ease 0.50s;
    box-shadow: 0px -1px rgba(255, 255, 255, 0.25) inset;
    white-space:nowrap;
    z-index:2000;
    @media(hover:hover) { &:hover{
        background-color: ${props => props.hovercolor || props.bgcolor};
        & * {
            color: black;
        }
    }}
    &::after{
        position:fixed;
        top:0px;
    }
`;
export const FlexItem = styled.div`
    flex: ${props => props.flexSize || "1"};
    text-align: ${props => props.align || "center"};
`;
export const StyledLink = styled(Link)`
    transition: all ease 0.50s;
    text-decoration: none;
    color: ${props => props.color || "white"};
    font-size: 16px;
    margin:20px;
    font-weight: 500;
    &:hover{
        color: ${props => props.hovercolor || props.color || "white"};
    }
    @media (max-width: 900px) {
        display:none;
    }
`
export const StyledHeaderLink = styled(StyledLink)`
    font-size: 32px;
    font-weight: 1000;
    letter-spacing: 0.2em;
    @media (max-width: 900px) {
        display:inline;
    }
    @media (max-width: 450px) {
        display:inline;
        font-size:24px;
    }
`
export const StyledButtonAnchor = styled.a`
    transition: all ease 0.50s;
    text-decoration: none;
    color: ${props => props.color || "white"};
    margin:20px;
    font-weight: 500;
    padding: 5px 20px;
    font-size: 18px;
    border-radius: 30px;
    border: 2px solid #c69c6d;
    background-color: transparent;
    
    &:hover{
        color: white;
        background-color: ${props => props.hoverColor || "#a3733b"};
        cursor:pointer;
    }
    @media (max-width: 900px){
        display:none;
    }
`
export const StyledButtonLink = styled(Link)`

    transition: all ease 0.50s;
    text-decoration: none;
    color: ${props => props.color || "white"};
    margin:20px;
    font-weight: 500;
    padding: 5px 20px;
    font-size: 18px;
    border-radius: 30px;
    border: 2px solid #c69c6d;
    background-color: transparent;
    
    &:hover{
        color: white;
        background-color: ${props => props.hoverColor || "#a3733b"};
    }
`
export const BarsIcon = styled.div`
    display:none;
    position:absolute;
    right:20px;
    transform: translateY(10%);
    @media(max-width:900px){
        transition: all ease 1s;
        display:${props => props.display?"block":"none"};
        &:hover{
            cursor:pointer;
            color:black;
        }
    }
`
export  const MobNavLinks = styled.div`
    transition: all ease 1s;
    display: flex;
    position: fixed;
    z-index:30;
    height:100vh;
    width: 100%;
    flex-direction:column;
    justify-content:space-around;
    padding-top:80px;
    align-items:stretch;
    text-align:center;
    background-color:rgba(22, 22, 24,0.995);
    display:none;
    & > * {
        margin:10px;
    }
    
    @media (max-width : 900px){
        display:${props => props.display?"block":"none"};
    }
`

export const MobNavLink = styled(Link)`
    transition: all ease 1s;
    display: block;
    flex: 1 0 100%;
    text-decoration:none;
    font-size:18px;
    color:white;
`
